import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const ParasitePrototype = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>protoype: parasite (project B)</h2>
        <p>For next week, protoype your idea for <Link to="../parasite">Project B</Link>. This prototype comprises <b>text and visuals</b>. If you are working in a team, a shared prototype is sufficient.</p>
        <p><b>Visual:</b></p>
        <p>Project B, a browser extension, interacts with existing pages on the web. Take a <b>screenshot</b> of one such pages and position it centrally on a blank background. On it and around it, add drawings and descriptions to <b>communicate</b> how your Browser Extension will act upon the content. You can decide to do this <b>digitally</b> (e.g. using Photoshop to add your remarks) <b>or on a print-out</b> of the screenshot.*</p>
        
        <br></br>

        <p><b>Text:</b></p>
        <p>Write a brief description of your project (minimum 300 words). Cover the following points</p>
        <ul>
          <li>
            <p><b>what</b> does it do?</p>
          </li>
          <li>
            <p>walk us through its doing using an <b>example</b>. (i.e. <i>'when visiting xyz.com, for example, this extension will...'</i>)</p>
          </li>
          <li>
            <p>what are <b>themes</b> your idea deals with?</p>
            <ul>
              <li>
                <p>your project might aim to make a comment, remix or critique on existing webpages, or their content. Describe the <b>message</b> your projects communicates and/or what <b>experience</b> it aims to create.</p>
              </li>
          </ul>
          </li>
        </ul>
        
        <br></br>
        <p><b>Submit to Class Wiki & Bring to class:</b></p>
        <p>Please scan your work, add it to your repo alongide the text, and push a link to the <a target="_blank" href="https://github.com/leoneckert/abc-browser-circus/wiki">Class Wiki</a>. Bring the visual part of your prototype to the next class, too.</p>
        <br></br>


        <p>*<b>optionally</b>, you may also use the below blank templates if they serve better to communicate your idea:</p>
        <ul>
          <li>
            <p><a href="../../abc-pp-a3-landscape.pdf" target="_blank">A3 Landscape</a></p>
          </li>
          <li>
            <p><a href="../../abc-pp-a3-portrait.pdf" target="_blank">A3 Portrait</a></p>
          </li>
          <li>
            <p><a href="../../abc-pp-a4-landscape.pdf" target="_blank">A4 Landscape</a></p>
          </li>
          <li>
            <p><a href="../../abc-pp-a4-portrait.pdf" target="_blank">A4 Portrait</a></p>

          </li>
        </ul>
      </MiniLayout>
    </Layout>
  )
}



export default ParasitePrototype